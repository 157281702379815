<template>
    <div class="wrapper">
        <div class="dragon"></div>
        <!-- <div class="answerList" v-show="ispopupData">
            <div class="answer" v-for="(item,index) in popupData" :key="index" @click="select(item)"> 
                <div class="text">{{ item.text }}</div>
                <div class="arrow"></div>
            </div>
        </div> -->
        <div class="dialogue" @click="click">
            <div class="nameBox">龙探长</div>
            <!-- <div class="name">龙探长</div> -->
            <div class="content" v-if="newList.displayType == 1">
                {{ text }}
            </div>
            <div class="cardBox" v-if="newList.displayType == 6" @click="jump(newList.link)">
                <div class="sj"></div>
                <div class="cardLeft">
                    <div class="textBox">{{ newList.title }}</div>
                    <div class="jump" v-show="newList.link"></div>
                    
                </div>
                <div class="cardRight">
                    <img :src="newList.imageUrl" alt="">
                </div>
            </div>
            <div class="videoBox">
                <video-player class=" video-player vjs-custom-skin"
                id="video"
    　　　　　　ref="videoPlayer"
    　　　　　　:playsinline="true"
    　　　　　　:options="playerOptions"
                @ready="onPlayerReady"
    　　　　　　></video-player>
                <!-- <video id="video"  ref="video"   src="https://sfq.fansiji.com/static/video.mp4" class="videoBox"></video> -->
            </div>
            
            <div class="triangle" v-show="isshow&&newList.displayType==1"></div>
            <div class="answerList" v-show="ispopupData">
                <div :class="selectIndex==index?'selectAnswer':'answer'" v-for="(item,index) in popupData" :key="index" @click="select(item,index)">
                    <div class="text">{{ item.text }}</div>
                    <div class="arrow"></div>
                </div>
            </div>
        </div>
        <popup ref="popup" />
    </div>
  </template>
  
  <script>
  import { videoPlayer } from 'vue-video-player'
  import 'video.js/dist/video-js.css'
  import 'vue-video-player/src/custom-theme.css'
  import { getSessionNew,getEvent,getEcho } from '@/api'
  import popup from '@/components/popup.vue'
  export default {
    components: { 
        popup,
        videoPlayer
     },
    props: {},
    data() {
      return {
        text:'',
        sessionId:'',
        time2:null,
        isshow:false,
        popupData:[],
        ispopupData:false,
        selectIndex:-1,
        isclick:false,
        list:{},
        names:'http://192.168.42.43:1234',
        newList:{},
        isIos:false,
        fileType:'mp4',
        url:''
      }
    },
    watch: {},
    computed: {
        play(){
            return this.$refs.videoPlayer.player
        },
    　　playerOptions () {
    　　　　const playerOptionsObj = {
                   muted:true,
        　　　　　　playbackRates: [0.7, 1.0, 1.5, 2.0], //视频播放速度
        　　　　　　autoplay: true, // 如果true，浏览器准备好时开始回放。
        　　　　　　muted: false, // 默认情况下将会消除任何音频。
        　　　　　　loop: false, // 导致视频一结束就重新开始。
        　　　　　　// preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）。
        　　　　　　language: 'zh-CN',
        　　　　　　// aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）。
        　　　　　　fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        　　　　　　sources: [{
        　　　　　　　　type: 'video/' + this.fileType, // 资源格式写法：'video/mp4'，否则控制台会出现notSupportedMessage设置的错误。
        　　　　　　　　src: this.url // 视频url地址
        　　　　　　}],
        　　　　poster: '', // 视频封面地址
        　　　　width: '100%',
        　　　　height: '100%', // 设置高度，fluid需要设置成flase
        　　　　notSupportedMessage: '此视频暂无法播放...', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        　　　　controlBar: {
        　　　　　　timeDivider: true,
        　　　　　　durationDisplay: true,
        　　　　　　remainingTimeDisplay: false,
        　　　　　　fullscreenToggle: true //全屏按钮
        　　　　}
        　　}
    　　    return playerOptionsObj
    　　}
    },
    methods: {
        onPlayerReady(){
            this.aaa()
            console.log('准备好了')

        },
        jump(url){
            location.href = url

        },
        getVideoCover(url) {
            let that = this
            return new Promise(function (resolve, reject) {
                let dataURL = '';
                let video = document.createElement("video");
                video.setAttribute('crossOrigin', 'anonymous');//处理跨域
                video.setAttribute('src', url);
                video.setAttribute('width', 300);
                video.setAttribute('height', 450);
                video.currentTime = 0.1
                video.addEventListener('loadeddata', function () {
                    let canvas = document.createElement("canvas"),
                        width = video.width, //canvas的尺寸和图片一样
                        height = video.height;
                    canvas.width = width;
                    canvas.height = height;
                    canvas.getContext("2d").drawImage(video, 0, 0, width, height); //绘制canvas
                    dataURL = canvas.toDataURL('image/jpeg'); //转换为base64
                    resolve(dataURL);
                });
            })
        },
        video(item){
            this.$refs.popup.url = item.imageUrl
            this.$refs.popup.show = true
            this.$refs.popup.play()

        },
        reveal(){
            let arr = this.cutString(this.list.text, 1);
            // this.newList.push({
            //     type:this.list[this.listNUm].type,
            //     text:''
            // })
            let num = 0
            this.time = setInterval(()=>{
                this.text = this.text + arr[num]
                
                // let middle= this.$refs["middle"];
                // middle.scrollTop = middle.scrollHeight;
                num++
                if(num == arr.length){
                    // if(this.listNUm<this.list.length){
                    //     setTimeout(()=>{
                    //         this.reveal()
                    //     },500)
                    // }
                    this.isshow = true
                    // setTimeout(()=>{
                    //     this.getEvent()
                    // },500)
                    clearInterval(this.time)
                }
            },20)

            
        },
        cutString(str, len) {
            var result = [];
            for (var i = 0; i < str.length; i += len) {
                result.push(str.substring(i, i + len));
            }
            return result;
        },
        getSessionNew(){
            getSessionNew({
                storyId:sessionStorage.getItem('storyId')?sessionStorage.getItem('storyId'):1,
                sceneId:1,
                eventId:0
            }).then(res=>{
                this.sessionId = res.data.data.sessionId
                this.polling()
            })
        },
        polling(){
            // this.time2 = setInterval(()=>{
                this.getEvent()
            // },1000)
        },
        click(){


        //             setTimeout(()=>{
        //     console.log(this.play,'yinp')
        //     this.play.play()

        // },1000)
            if(!this.isshow){
                return
            }
            this.isshow = false
            this.getEvent()

        },
        select(item,index){
            if(this.isclick){
                return
            }
            this.isclick = true
            this.selectIndex = index
            this.getEcho(item)

        },
        getEcho(item){
            let data = item
            if(item.id == 20){
                setTimeout(()=>{
                    
                    if(this.newList.displayType == 5){
                        const u = navigator.userAgent;
                        const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
                        console.log(isiOS,'是否苹果')
                        if (isiOS) {
                            this.isIos = true
                            console.log('苹果')
                            
                            
                            
                            
                        }else{
                            console.log('安卓')
                            
                            this.$refs.popup.url = this.newList.imageUrl
                            this.$refs.popup.show = true 
                            this.$refs.popup.play()

                        }
                        

                    }
                    
                   

                },1000)
                

            }
            
                // console.log('csrrrss')

                // this.cs()
            getEcho({
                sessionId:this.sessionId,
                eventId:data.id
            }).then(res=>{
                // this.popupData = []
                setTimeout(()=>{
                    this.ispopupData = false
                    this.isclick = false
                    this.selectIndex = -1
                    this.polling()
                },500)


            })

        },
        cs(){

        },
        addBg(data){
            
            this.text = ''
            this.list = data.eventList[0]
            //this.list.imageUrl = this.names + this.list.imageUrl
            this.list.imageUrl = this.list.imageUrl
            // if(data.eventList[0].displayType == 5){
            //     // let res = this.getVideoCover(this.list.imageUrl)
            //     let res = await this.getVideoCover(this.list.imageUrl)
            //     this.list.bgImg = res
                
            // }
            this.newList =  this.list

            this.reveal()
            console.log(data.eventList[0].displayType)
            if(data.eventList[0].displayType == 5){
                const u = navigator.userAgent;
                const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
                console.log(isiOS,'是否苹果')
                if (isiOS) {
                    this.isIos = true
                    console.log('苹果')
                    
                    
                    
                }else{
                    console.log('安卓')
                    
                    this.$refs.popup.url = this.newList.imageUrl
                    this.$refs.popup.show = true 
                    this.$refs.popup.play()

                }


                // this.$refs.popup.url = this.newList.imageUrl
                // this.$refs.popup.show = true
                // this.$refs.popup.play()
                setTimeout(()=>{
                    this.getEvent()
                },2000)
            }

        },
        aaa(){
                console.log(this.play,'yinp')
                this.play.play()

        },
        getEvent(){
            // setTimeout(()=>{
                

            // },800)
            
            getEvent({
                sessionId:this.sessionId,
                eventId:0
            }).then(res=>{
                
                let data = res.data.data
                if(data.eventType == 1){
                    
                    
                    if(data.eventList[0].displayType == 1||data.eventList[0].displayType == 5||data.eventList[0].displayType == 6){
                       
                        
                        
                                    
                        this.text = ''
                        this.list = data.eventList[0]
                        //this.list.imageUrl = this.names + this.list.imageUrl
                        // this.list.imageUrl = this.list.imageUrl
                        // if(data.eventList[0].displayType == 5){
                        //     // let res = this.getVideoCover(this.list.imageUrl)
                        //     let res = await this.getVideoCover(this.list.imageUrl)
                        //     this.list.bgImg = res
                            
                        // }
                        this.newList =  this.list
                        this.url = this.newList.imageUrl
                        

                        this.reveal()

                            // this.$refs.popup.url = this.newList.imageUrl
                            // this.$refs.popup.show = true
                            // this.$refs.popup.play()
                            setTimeout(()=>{
                                this.getEvent()
                            },2000)
                        // this.addBg(data)

                    }
                    //对话
                    // this.list.push({
                    //     type:data.eventList[0].roleType,
                    //     text:data.eventList[0].text
                    // })

                    // this.reveal()
                    // if(data.eventList[0].roleType == 1){
                    //     clearInterval(this.time2)
                    //     this.time2 = null
                    //     return
                    // }
                }else if(data.eventType == 2){
                    // clearInterval(this.time2)
                    // this.time2 = null
                    this.popupData = data.eventList
                    
                    this.ispopupData = true
                    //选项
                    // this.$refs.popup.show = true
                }else{
                    this.ispopupData = true
                    // if(this.time2 == null){
                    //     this.polling()
                    // }
                    // console.log(this.time2,'322222222222')
                    // setTimeout(()=>{
                    //     this.getEvent()

                    // },500)
                    

                }

            })

        }
  
    },
    created() { 
        this.getSessionNew()
    
     },
    mounted() {
        // setTimeout(()=>{
        //     console.log(this.play,'yinp')
        //     this.play.play()

        // },1000)
     }
  }
  </script>
  <style scoped lang="scss">
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-image: url(~@/assets/images/bg.png);
    background-size: 100% auto;
    background-position: top;
    background-repeat: no-repeat;

    // .answerList{
    //     position: fixed;
    //     right: 0;
    //     top: 2rem;
    //     .answer{
    //         width: 5.09rem;
    //         height: .71rem;
    //         display: flex;
    //         align-items: center;
    //         margin-top: .2rem;
    //         background: linear-gradient(90deg, rgba(230, 230, 230, 0) 8%, #EFF0FF 96%);

    //         .arrow{
    //             width: .26rem;
    //             height: .15rem;
    //             background-image: url(~@/assets/images/arrow1.png);
    //             background-size: 100% auto;
    //             background-position: top;
    //             background-repeat: no-repeat;
    //         }

    //         .text{
    //             width: 3.5rem;
    //             height: .71rem;
    //             line-height: .71rem;
    //             font-size: .28rem;
    //             color: #3D5786;
    //             text-align: center;
    //             margin-left: 1.2rem;
    //             font-weight: 500;

    //         }

    //     }
    // }



    .dragon{
        width: 5.57rem;
        height: 8.46rem;
        position: fixed;
        bottom: 2rem;
        z-index: 5;
        background-image: url(~@/assets/images/dragon.png);
        background-size: 100% auto;
        background-position: top;
        background-repeat: no-repeat;
    }
    .dialogue{
        width: 100vw;
        padding: .8rem 0 .6rem 0;
        position: fixed;
        bottom: 0;
        z-index: 6;
        background: linear-gradient(0deg, #090E1F 11%,#224176 80%, #22417600 100%);

        .nameBox{
            width: 2.38rem;
            height: .57rem;
            background-image: url(~@/assets/images/nameBox.png);
            background-size: 100% auto;
            background-position: top;
            background-repeat: no-repeat;
            margin-top: -.8rem;
            font-size: .28rem;
            color: #fff;
            line-height: .55rem;
            text-align: center;
            
        }
        .answerList{
            width: 5rem;
            margin: .4rem auto .2rem auto;
            .selectAnswer{
                padding: .05rem 0;
                min-height: .7rem;
                width: 5rem;
                // background-image: url(~@/assets/images/selectAnswer.png);
                // background-size: 100% auto;
                // background-position: top;
                // background-repeat: no-repeat;
                border: .1rem solid #4C7CC8;
                background-color: #FFFFFF;
                margin-top: .2rem;
                border-radius: .55rem;
                
                margin-top: .2rem;
                display: flex;
                justify-content: center;
                align-items: center;

                .text{
                    width: 3.8rem;
                    color: #2E58D0;
                    text-align: center;
                    font-size: .28rem;
                    font-weight: bold;
                }

                .arrow{
                    width: .26rem;
                    height: .15rem;
                    background-image: url(~@/assets/images/arrow1.png);
                    background-size: 100% auto;
                    background-position: top;
                    background-repeat: no-repeat;

                }
            }

            .answer{
                padding: .05rem 0;
                min-height: .7rem;
                width: 5rem;
                // height: .91rem;
                // background-image: url(~@/assets/images/answer.png);
                // background-size: 100% auto;
                // background-position: top;
                // background-repeat: no-repeat;
                border: .1rem solid #D1D0DB;
                background-color: #2B5788;
                margin-top: .2rem;
                border-radius: .55rem;
                display: flex;
                justify-content: center;
                align-items: center;

                .text{
                    width: 3.8rem;
                    color: #fff;
                    text-align: center;
                    font-size: .28rem;
                    font-weight: bold;
                    
                }

                .arrow{
                    width: .26rem;
                    height: .15rem;
                    background-image: url(~@/assets/images/arrow2.png);
                    background-size: 100% auto;
                    background-position: top;
                    background-repeat: no-repeat;


                }
            }
            
        }

        .triangle{
            width: 0;
            border-right: .1rem solid transparent;
            border-top: .2rem solid #fff;
            border-left: .1rem solid transparent;
            margin: .2rem 0 0 7rem;
            position: relative;
            // position: absolute;
            // right: .8rem;
            // bottom: .3rem;
            animation: likes .8s ease 0s infinite alternate;
        }

        @keyframes likes {
            0% {
                top: 0;
            }

            100% {
                top: .1rem;
            }
        }
        .sj{
            width: 0;
            border-right: .2rem solid #fff;
            border-top: .1rem solid transparent;
            border-bottom: .1rem solid transparent;
            position: absolute;
            left: -.18rem;
            top: .25rem;
        }
        .videoBox{
            width: 3rem;
            height: 4.5rem;
            position: absolute;
            margin: .2rem auto 0 auto;
            bottom: 200vh;
            .button{
                width: .8rem;
                height: .8rem;
                position: absolute;
                top: 2rem;
                left: 1.5rem;
                margin-top: -.4rem;
                margin-left: -.4rem;
                //play
                background-image: url(~@/assets/images/play.png);
                background-size: 100% auto;
                background-position: top;
                background-repeat: no-repeat;
            }
        }
        .cardBox{
            width: 6rem;
            display: flex;
            margin: .2rem auto 0 auto;
            position: relative;
            border-radius: .25rem;
            padding: .15rem;
            background-color: #fff;

            .cardLeft{
                    width: 4rem;
                    margin-right: .6rem;

                    .textBox{
                        width: 4rem;
                        height: 1.2rem;
                        font-weight: bold;
                        font-size: .24rem;

                    }

                    .jump{
                        width: 1.32rem;
                        height: .32rem;
                        background-image: url(~@/assets/images/cardJump2.png);
                        background-size: 100% auto;
                        background-position: top;
                        background-repeat: no-repeat;
                        margin-top: .2rem;


                    }

                }

                .cardRight{
                    width: 1.2rem;
                    height: 1.2rem;

                    img{
                        width: 100%;
                        height: 100%;
                    }
                }

        }
        .content{
            width: 7rem;
            min-height: 1rem;
            margin: .2rem auto 0 auto;
            font-size: .28rem;
            font-weight: 200;
            color: #fff;
            white-space: pre-wrap;
        }
        .name{
            width: 1.8rem;
            height: .55rem;
            font-size: .28rem;
            color: #fff;
            line-height: .55rem;
            text-align: center;
        }
    }
    
  }
  .customBtn{
    width: 100vw;
    height: 100vh;
  }
  </style>